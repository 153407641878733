@tailwind base;
@tailwind components;
@tailwind utilities;

a.navlink {
    @apply text-lg text-white font-normal px-6 hover:underline underline-offset-8 decoration-2 hover:text-slate-200;
}

section.publications {
    @apply h-full p-2 m-2 flex flex-col align-top box-border;
}

section.publications h1 {
    @apply text-xl font-bold text-center;
}

section.publications ul {
    @apply list-disc leading-8 p-4 m-2;
}

section.publications li {
    @apply p-2;
}


section.publications strong {
    @apply font-semibold;
}

a {
    @apply text-secondary-800;
    @apply hover:text-secondary-600;
}